import { DeviceEvent } from "../models/DeviceEvent";
import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";

let subscribed = false;

export function useDeviceEventQuery(deviceId: string, eventId: number) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['getEvent', deviceId, eventId],
        queryFn: async () => {
            return OCGatewayClient.request('getEvent', {
                deviceId,
                eventId,
                subscribe: true
            }) as Promise<DeviceEvent>;
        },
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    if (!subscribed) {
        OCGatewayClient.user$.subscribe(user => {
            console.log(`(deviceEventQuery) User changed, invalidating events...`);
            queryClient.invalidateQueries({
                queryKey: ['getEvent']
            });
        });

        OCGatewayClient.socket.on('eventUpdate', (data: any) => {
            queryClient.setQueryData(['getEvent', data.deviceId, data.eventId], (event: DeviceEvent) => {
                if (!event) {
                    console.log('eventUpdate (ignored)', data);
                    return;
                }

                if (data.type === 'update') {
                    console.log('eventUpdate (relevant update)', data);
                    return Object.assign({}, event, data.body);
                }

                console.log('eventUpdate (no match)', data);
            });
        });

        subscribed = true;
    }

    return query;
}

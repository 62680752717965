import React, {useState, useEffect} from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import {IonButton, IonIcon} from "@ionic/react";
import {downloadOutline} from "ionicons/icons";
import {App} from "@capacitor/app";

const SoftwareUpdateIndicatorButton: React.FC = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        if(!navigator.serviceWorker) {
            console.log('<SoftwareUpdateIndicatorButton> No service worker support available');
            return;
        }

        navigator.serviceWorker.ready.then((registration) => {
            App.addListener('appStateChange', async ({ isActive }) => {
                console.log('App state changed. isActive', isActive);

                if(isActive) {
                    console.debug('<SoftwareUpdateIndicatorButton> Checking for updates (foreground).');
                    await registration.update();
                }
            });

            setInterval(async () => {
                console.debug('<SoftwareUpdateIndicatorButton> Checking for updates (periodic).');
                await registration.update();
            }, 1000 * 60 * 5);
        });

        serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (showReload ? <IonButton onClick={reloadPage} color="warning">
        <IonIcon slot="icon-only" icon={downloadOutline} />
    </IonButton> : null);
};

export default SoftwareUpdateIndicatorButton;

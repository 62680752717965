import { IonChip, IonIcon, IonItem, IonLabel, IonNote, IonSkeletonText } from "@ionic/react";
import { EventTriggerSource, EventClassification } from "../models/DeviceEvent";
import Timestamp from "./Timestamp";
import OCGatewayClient from "../client/OCGatewayClient";
import styles from './DeviceEventItem.module.css';
import { useImage } from 'react-image';
import { alertCircleOutline, ellipsisHorizontalCircleOutline, enterOutline, exitOutline, helpCircleOutline, keyOutline, personCircleOutline, phonePortraitOutline, shieldCheckmarkOutline, warningOutline } from "ionicons/icons";
import RfidChip from "./RfidChip";
import { useDeviceEventQuery } from "../query/deviceEventQuery";

interface DeviceEventItemProps {
    deviceId: string;
    eventId: number;
}

function iconForEventTriggerSource(eventTriggerSource?: EventTriggerSource | null) {
    return {
        [EventTriggerSource.IndoorMotion]: exitOutline,
        [EventTriggerSource.OutdoorMotion]: enterOutline,
        [EventTriggerSource.Manual]: keyOutline,
        [EventTriggerSource.Remote]: phonePortraitOutline,
    }[eventTriggerSource!] || helpCircleOutline;
}

function labelForEventTriggerSource(eventTriggerSource?: EventTriggerSource | null) {
    return {
        [EventTriggerSource.IndoorMotion]: 'Exit Allowed',
        [EventTriggerSource.OutdoorMotion]: 'Outdoor Motion',
        [EventTriggerSource.Manual]: 'Manual Unlock',
        [EventTriggerSource.Remote]: 'Remote Unlock',
    }[eventTriggerSource!] || 'Unknown';
}

function colorForEventClassification(eventClassification?: EventClassification | null) {
    return {
        [EventClassification.Unknown]: 'medium',
        [EventClassification.Clear]: 'success',
        [EventClassification.Suspicious]: 'warning',
        [EventClassification.Contraband]: 'danger',
        [EventClassification.HumanActivity]: 'primary',
        [EventClassification.RemoteUnlock]: 'secondary',
    }[eventClassification!] || 'medium';
}

function iconForEventClassification(eventClassification?: EventClassification | null) {
    return {
        [EventClassification.Unknown]: ellipsisHorizontalCircleOutline,
        [EventClassification.Clear]: shieldCheckmarkOutline,
        [EventClassification.Suspicious]: alertCircleOutline,
        [EventClassification.Contraband]: warningOutline,
        [EventClassification.HumanActivity]: personCircleOutline,
        [EventClassification.RemoteUnlock]: phonePortraitOutline,
    }[eventClassification!] || ellipsisHorizontalCircleOutline;
}

function labelForEventClassification(eventClassification?: EventClassification | null) {
    return {
        [EventClassification.Unknown]: 'No Activity',
        [EventClassification.Clear]: 'Entry Allowed',
        [EventClassification.Suspicious]: 'Contraband Suspected',
        [EventClassification.Contraband]: 'Contraband Detected',
        [EventClassification.HumanActivity]: 'Human Activity',
        [EventClassification.RemoteUnlock]: 'Remote Unlock',
    }[eventClassification!] || 'Unknown';
}

const DeviceEventItem: React.FC<DeviceEventItemProps> = ({ deviceId, eventId }) => {
    const query = useDeviceEventQuery(deviceId, eventId);

    const { src: posterFrameSrc } = useImage({
        srcList: query.data ? [`${OCGatewayClient.apiURL}/events/${deviceId}/${eventId}/${query.data.posterFrameIndex || 0}`] : [],
        useSuspense: false
    });

    if (query.isLoading) {
        return <IonItem routerLink={`/events/${deviceId}/${eventId}`} className={styles.item}>
            <IonSkeletonText slot="start" className={styles.image} animated={true}></IonSkeletonText>
            <IonLabel>
                <div className={styles.chips}>
                    <IonChip color="medium">
                        <IonLabel><IonSkeletonText animated={true} style={{ width: '56px' }} /></IonLabel>
                    </IonChip>
                </div>
                <IonNote><IonSkeletonText animated={true} style={{ width: '120px' }} /></IonNote>
                <IonNote><IonSkeletonText animated={true} style={{ width: '100px' }} /></IonNote>
            </IonLabel>
        </IonItem>
    }
    else if (query.isError) {
        return <IonItem routerLink={`/events/${deviceId}/${eventId}`} className={styles.item}>
            <IonLabel>
                <div className={styles.chips}>
                    <IonChip color="medium">
                        <IonLabel>Error</IonLabel>
                    </IonChip>
                </div>
                <IonNote>Failed to load event</IonNote>
            </IonLabel>
        </IonItem>
    }
    else
    {
        const event = query.data!;

        return <IonItem routerLink={`/events/${deviceId}/${eventId}`} className={styles.item}>
            {posterFrameSrc
                ? <img slot="start" className={styles.image} src={posterFrameSrc} alt="" />
                : <IonSkeletonText slot="start" className={styles.image} animated={true}></IonSkeletonText>}
            <IonLabel>
                <div className={styles.chips}>
                    {event.rfidCodes?.map(rfidCode => <RfidChip key={rfidCode} rfidCode={rfidCode} deviceId={deviceId} />)}
                </div>
                {event.eventTriggerSource === EventTriggerSource.OutdoorMotion ?
                    <div className={`${styles.eventOutcome} ion-color-${colorForEventClassification(event.eventClassification)}`}>
                        <IonIcon icon={iconForEventClassification(event.eventClassification)} />
                        <IonLabel>{labelForEventClassification(event.eventClassification)}</IonLabel>
                    </div>
                    :
                    <div className={`${styles.eventOutcome} ion-color-primary`}>
                        <IonIcon icon={iconForEventTriggerSource(event.eventTriggerSource)} />
                        <IonLabel>{labelForEventTriggerSource(event.eventTriggerSource)}</IonLabel>
                    </div>
                }
                <IonNote><Timestamp timestamp={event.timestamp || undefined} /></IonNote>
            </IonLabel>
        </IonItem>
    }
};

export default DeviceEventItem;
import { useEffect, useRef, useState } from "react";
import { formatDistanceToNowStrict, differenceInCalendarDays, differenceInHours, differenceInMinutes, differenceInSeconds, format } from 'date-fns';

interface TimestampProps {
    timestamp?: Date | number;
    className?: string;
}

const formatTimestamp = (timestamp: Date) => {
    const now = new Date();
    const diffInDays = differenceInCalendarDays(now, timestamp);

    // Note:
    // 'p' Token: Represents the localized time format (e.g., 2:30 PM in en-US or 14:30 in en-GB).
	// 'P' Token: Represents the localized date format (e.g., 09/10/2024 in en-US or 10/09/2024 in en-GB).

    if (differenceInSeconds(now, timestamp) < 20) {
        return 'just now';
    } else if (differenceInHours(now, timestamp) < 1) {
        return formatDistanceToNowStrict(timestamp, { addSuffix: true });
    } else if (diffInDays < 1) {
        return format(timestamp, 'p');
    } else if (diffInDays < 2) {
        return `Yesterday, ${format(timestamp, 'p')}`;
    } else if (diffInDays < 7) {
        return format(timestamp, 'EEEE p');
    } else {
        return format(timestamp, 'P p');
    }
};

const Timestamp: React.FC<TimestampProps> = ({ timestamp, className }) => {
    const [strTimestamp, setStrTimestamp] = useState<string | undefined>();
    const timerRef = useRef<any>(null);

    useEffect(() => {
        if (!timestamp) {
            setStrTimestamp(undefined);
            return;
        }

        // Function to calculate the delay based on the age of the timestamp
        const calculateDelay = (date: Date) => {
            const now = new Date();
            if (differenceInSeconds(now, date) < 60) {
                return 1000; // refresh every second
            } else if (differenceInMinutes(now, date) < 60) {
                return 30000; // refresh every 30 seconds
            } else {
                return 60000; // refresh every minute
            }
        };

        // Function to update the timestamp string and schedule the next refresh
        const refreshTimestamp = () : any => {
            const date = new Date(timestamp);
            setStrTimestamp(formatTimestamp(date));

            const delay = calculateDelay(date);
            timerRef.current = setTimeout(refreshTimestamp, delay);
        };

        // Schedule the initial refresh
        refreshTimestamp();

        // Cleanup function to clear the timeout when the component unmounts or when the timestamp changes
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [timestamp]);

    return <span className={className}>{strTimestamp}</span>;
};

export default Timestamp;
import { IonChip, IonIcon, IonItem, IonLabel, IonSkeletonText, IonThumbnail } from "@ionic/react";
import { banOutline, refreshOutline, unlinkOutline, wifi } from "ionicons/icons";
import { useDeviceQuery } from "../query/deviceQuery";
import styles from './DeviceListItem.module.css';
import { MouseEventHandler } from "react";

interface DeviceListItemProps {
    deviceId: string;
    onClick?: MouseEventHandler<Element>;
}

const DeviceListItem: React.FC<DeviceListItemProps> = ({ deviceId, onClick }) => {
    const deviceQuery = useDeviceQuery(deviceId);

    if (deviceQuery.isLoading) {
        return <IonItem button={true} routerLink={onClick ? undefined : `/devices/${deviceId}`} onClick={onClick} className={styles.item}>
            <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
            </IonThumbnail>
            <IonLabel>
                <h2>{deviceId}</h2>
                <p><IonSkeletonText animated={true} style={{ width: '160px' }} /></p>
                <IonChip color="medium" style={{ marginLeft: 0 }}>
                    <IonLabel><IonSkeletonText animated={true} style={{ width: '75px' }} /></IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
    else if (deviceQuery.isError) {
        return <IonItem button={true} detail={true} detailIcon={refreshOutline} onClick={() => deviceQuery.refetch()} className={styles.item}>
            <IonThumbnail slot="start">
                <img src="/assets/device/onlycat-device.png" alt="OnlyCat Device" />
            </IonThumbnail>
            <IonLabel>
                <h2>{deviceId}</h2>
                <IonChip color="danger" style={{ marginLeft: 0 }}>
                    <IonIcon icon={banOutline} />
                    <IonLabel>Unavailable</IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
    else {
        const device = deviceQuery.data;

        return <IonItem button={true} routerLink={onClick ? undefined : `/devices/${deviceId}`} onClick={onClick} className={styles.item}>
            <IonThumbnail slot="start">
                <img src="/assets/device/onlycat-device.png" alt="OnlyCat Device" />
            </IonThumbnail>
            <IonLabel>
                <h2>{device.deviceId}</h2>
                <p>{device.description}</p>
                <IonChip color={device.connectivity?.connected ? 'success' : 'danger'} style={{ marginLeft: 0 }}>
                    <IonIcon icon={device.connectivity?.connected ? wifi : unlinkOutline} />
                    <IonLabel>{device.connectivity?.connected ? 'Online' : 'Offline'}</IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
};

export default DeviceListItem;

import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery } from "@tanstack/react-query";

export function useJiraTicketsQuery(queryString?: string) {
    const query = useQuery({
        queryKey: ['getJiraTickets', queryString],
        queryFn: async (): Promise<any> => {
            return OCGatewayClient.request('getJiraTickets', {
                query: queryString
            });
        },
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 1,
        networkMode: 'always',
        retry: false
    });

    return query;
}

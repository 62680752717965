import { useQuery } from "@tanstack/react-query";
import OCGatewayClient from "../client/OCGatewayClient";
import { TimeZone } from "../models/TimeZone";

export function useTimeZonesQuery() {
    const query = useQuery({
        queryKey: ['getTimeZones'],
        queryFn: async () => {
            return OCGatewayClient.request('getTimeZones', {}) as Promise<TimeZone[]>;
        },
    })

    return query;
}
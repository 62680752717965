import OCGatewayClient from "./OCGatewayClient";
import OCAppPlugin from "../plugins/OCAppPlugin";
import { PushNotifications } from "@capacitor/push-notifications";
import { isPlatform } from "@ionic/react";
import { history } from "../utils/history";
import OCApp from "../plugins/OCAppPlugin";
import { App } from "@capacitor/app";

class OCPushNotifications {
    constructor() {
        if (isPlatform('capacitor')) {
            App.addListener('appStateChange', async ({ isActive }) => {
                if (isActive) {
                    await PushNotifications.removeAllDeliveredNotifications();
                }
            });

            (async () => {
                await this.addPushListeners();
                await this.addPushActionListeners();
                await this.createChannels();
                await this.register();
            })();
        }
    }

    async register() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android may just grant without prompting
        let result;

        try {
            result = await OCApp.requestCriticalAlertPermissions();
        } catch (e) {
            console.warn("<Account.registerPushNotifications> OCApp.requestCriticalAlertPermissions not successful, falling back to PushNotifications.requestPermissions", e);
            result = await PushNotifications.requestPermissions();
        }

        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await PushNotifications.register();
        } else {
            console.warn('(OCPushNotifications.register) Error requesting permission: ' + result.receive);
        }
    }

    async getPushEnvironment() {
        if (isPlatform('android')) {
            return 'fcm';
        }

        try {
            const { environment } = await OCAppPlugin.getPushEnvironment();
            return environment;
        }
        catch (e) {
            return 'Not Available';
        }
    }

    async registerPushToken(pushToken: string, environment?: string) {
        if (!environment) {
            environment = await this.getPushEnvironment();
        }

        return OCGatewayClient.request('registerPushToken', {
            pushToken,
            environment
        });
    }

    async addPushListeners() {
        await PushNotifications.addListener('registration', async (token) => {
            try {
                await this.registerPushToken(token.value);
            } catch (e) {
                alert('Registration request error: ' + JSON.stringify(e));
            }
        });

        await PushNotifications.addListener('registrationError', err => {
            alert('Registration error: ' + err.error);
        });
    }

    async addPushActionListeners() {
        await PushNotifications.addListener('pushNotificationActionPerformed', async (action) => {
            console.log('(OCPushNotifications) pushNotificationActionPerformed: ', action);

            const data = action.notification.data;

            switch (action.actionId) {
                case 'UNLOCK_ACTION':
                    await OCGatewayClient.httpRequest('POST', `/devices/${data.deviceId}/unlock`, {});
                    break;

                case 'tap':
                    if (data.deviceId && data.eventId) {
                        history.push(`/events/${data.deviceId}/${data.eventId}`);
                    }
            }
        });

        await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
            console.log('(OCPushNotifications) pushNotificationReceived: ', notification);
        });
    }

    async createChannels() {
        if (isPlatform('android')) {
            try {
                await PushNotifications.createChannel({
                    description: 'Entry attempt with prey detected',
                    id: 'prey_detected',
                    importance: 5,
                    lights: true,
                    name: 'Prey Detected',
                    sound: 'four_dings.wav',
                    vibration: true,
                    visibility: 1,
                    lightColor: '#FF0000'
                });

                console.log('(OCPushNotifications) push channel created: prey_detected');
            }
            catch (error) {
                console.error('(OCPushNotifications) push channel error: ', error);
            }
        }
    }
}

export default new OCPushNotifications();
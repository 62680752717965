import {useIonToast} from "@ionic/react";
import OCGatewayClient from "../client/OCGatewayClient";
import {useEffect, useState} from "react";
import {alertCircle, checkmarkCircle} from "ionicons/icons";
import {Haptics, NotificationType} from "@capacitor/haptics";

function useUX() {
    const [connectionState, setConnectionState] = useState<string | null>(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [presentToast] = useIonToast();

    useEffect(() => {
        const subscriptions = [
            OCGatewayClient.connectionState$.subscribe(state => {
                setConnectionState(state);
                setIsConnecting(state === 'CONNECTING' || state === 'RECONNECTING');
            }),
        ];

        return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    }, []);

    async function presentResult(fn: Function, successMessage?: string) {
        try {
            await fn();

            Haptics.notification({type: NotificationType.Success}).catch(e => e);
            await presentToast({
                message: successMessage ?? 'Operation Successful',
                duration: 2500,
                color: 'success',
                icon: checkmarkCircle
            });
        } catch (e: any) {
            Haptics.notification({type: NotificationType.Error}).catch(e => e);
            await presentToast({
                message: e.message,
                duration: 2500,
                color: 'danger',
                icon: alertCircle
            });
        }
    }

    return {
        presentResult,
        connectionState,
        isConnecting
    }
}

export default useUX;
import { IonIcon, useIonModal } from "@ionic/react";
import styles from "./DeviceTransitPolicyTile.module.css";
import { addOutline } from "ionicons/icons";
import AddDoorPolicyModal from "../modals/AddDoorPolicyModal";

interface Props {
    deviceId: string;
}

const DeviceTransitPolicyAddButton: React.FC<Props> = ({ deviceId }) => {
    const [presentAddModal, dismissAddModal] = useIonModal(AddDoorPolicyModal, {
        deviceId,
        dismiss: (data: string, role: string) => dismissAddModal(data, role)
    });

    return (
        <button className={`${styles.tile} ${styles.add}`} onClick={() => presentAddModal({
            presentingElement: document.getElementsByTagName('ion-router-outlet')[0]
        })}>
            <IonIcon icon={addOutline} />
            <label>Add</label>
        </button>
    );
};

export default DeviceTransitPolicyAddButton;

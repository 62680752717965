import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonPage, IonSearchbar, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useJiraTicketsQuery } from '../query/jiraTicketsQuery';
import { useState } from 'react';
import DeviceListItem from '../components/DeviceListItem';
import styles from './JiraTicketSearchModal.module.css';

const JiraTicketSearchModal = ({ dismiss }: { dismiss: (data?: null | string, role?: 'navigate' | string) => void }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const query = useJiraTicketsQuery(searchQuery);

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Find Ticket</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="medium" onClick={() => dismiss(null, 'close')}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        value={searchQuery}
                        onIonInput={(e: any) => setSearchQuery(e.target.value)} />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {query.data && query.data.sections.filter((section: any) => section.issues?.length).map((section: any) => (
                    <IonList key={section.id} className={styles.ticketList}>
                        <IonListHeader>
                            <IonLabel>{section.label}</IonLabel>
                        </IonListHeader>
                        {section.issues.map((issue: any) => (
                            <>
                                <IonItemDivider>
                                    <IonLabel><div dangerouslySetInnerHTML={{ __html: issue.summary }}></div></IonLabel>
                                </IonItemDivider>
                                {issue.tickets?.map((ticket: any) => (
                                    <>
                                        <IonItem key={ticket.key} onClick={e => window.open(ticket.url, '_blank')} button>
                                            <IonThumbnail aria-hidden slot="start">
                                                <img alt="" src={ticket.iconUrl} />
                                            </IonThumbnail>
                                            <IonLabel>
                                                <IonText>{ticket.summary}</IonText>
                                                <p>{ticket.key}</p>
                                            </IonLabel>
                                            <IonText slot="end" color="medium">{ticket.status}</IonText>
                                        </IonItem>
                                        {ticket.key.startsWith('OCM-') && <DeviceListItem deviceId={'OC-' + ticket.summary} onClick={() => dismiss('/devices/OC-' + ticket.summary, 'navigate')} />}
                                    </>)
                                )}
                            </>
                        ))}
                    </IonList>
                ))}
            </IonContent>
        </IonPage >
    );
};

export default JiraTicketSearchModal;
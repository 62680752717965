import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover, useIonAlert, useIonModal } from "@ionic/react";
import styles from "./DeviceTransitPolicyTile.module.css";
import { useDeviceTransitPolicyActivateMutation, useDeviceTransitPolicyQuery } from "../query/deviceTransitPolicyQuery";
import { TransitPolicy } from "../models/TransitPolicy";
import useUX from "../utils/ux";
import DoorPolicyModal from "../modals/DoorPolicyModal";
import { createOutline, syncOutline, trashOutline } from "ionicons/icons";
import { useDeviceTransitPolicyDeleteMutation } from "../query/deviceTransitPoliciesQuery";

interface ContainerProps {
  deviceId: string,
  deviceTransitPolicyId: number;
  active: boolean;
}

function lockIconNameForTransitPolicy(transitPolicy?: TransitPolicy) {
  if (!transitPolicy?.rules?.some(rule => rule?.action?.lock === false)) {
    //If no rule can unlock, this is a remain locked policy
    return 'lock-locked';
  }

  if (transitPolicy?.rules?.some(rule => rule?.criteria)) {
    //If any rule has criteria, this is a smart lock policy
    return 'lock-smart';
  }

  return 'lock-unlocked';
}

const DeviceTransitPolicyTile: React.FC<ContainerProps> = ({deviceId, deviceTransitPolicyId, active }) => {
  const id = 'DeviceTransitPolicyTile' + deviceTransitPolicyId;
  const query = useDeviceTransitPolicyQuery(deviceTransitPolicyId);
  const activateMutation = useDeviceTransitPolicyActivateMutation(query.data?.deviceId!);
  const deleteMutation = useDeviceTransitPolicyDeleteMutation(deviceId);
  const { presentResult } = useUX();
  const [presentEditModal, dismissEditModal] = useIonModal(DoorPolicyModal, {
    dismiss: (data: string, role: string) => dismissEditModal(data, role),
    deviceTransitPolicyId
  });
  const [presentAlert] = useIonAlert();

  const activate = async () => {
    presentResult(() => activateMutation.mutateAsync(deviceTransitPolicyId), "Door Policy Activated");
  }

  const deletePolicy = async () => {
    presentAlert({
      header: 'Delete Door Policy',
      message: 'Are you sure you want to delete this door policy?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            presentResult(() => deleteMutation.mutateAsync(deviceTransitPolicyId), "Door Policy Deleted");
          }
        }
      ]
    });
  }

  return (
    <button id={id} className={[styles.tile, active ? styles.active : styles.inactive, activateMutation.isPending && styles.activating].join(' ')} >
      <IonIcon src={`/assets/lock/${lockIconNameForTransitPolicy(query.data?.transitPolicy)}.svg`} />
      <label>{query.data?.name}</label>
      <IonPopover trigger={id} dismissOnSelect>
        <IonContent>
          <IonList>
            <IonItem button={true} detail={false} disabled={active} onClick={activate}>
              <IonIcon slot="start" icon={syncOutline} />
              <IonLabel><strong>Activate</strong></IonLabel>
            </IonItem>
            <IonItem button={true} detail={false} onClick={() => presentEditModal({ presentingElement: document.getElementsByTagName('ion-router-outlet')[0] })}>
              <IonIcon slot="start" icon={createOutline} />
              <IonLabel>Edit Policy...</IonLabel>
            </IonItem>
            <IonItem button={true} detail={false} disabled={active} onClick={deletePolicy}>
              <IonIcon slot="start" icon={trashOutline} />
              <IonLabel color="danger">Delete Policy</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
    </button>
  );
};

export default DeviceTransitPolicyTile;
import {registerPlugin} from '@capacitor/core';
import type { PermissionState } from '@capacitor/core';

export interface OCAppPlugin {
    requestCriticalAlertPermissions(): Promise<PermissionStatus>;
    getPushEnvironment(): Promise<PushEnvironment>
}

const OCApp = registerPlugin<OCAppPlugin>('OCApp');

export interface PermissionStatus {
    receive: PermissionState;
}

declare type PushEnvironmentType = 'development' | 'production';

export interface PushEnvironment {
    environment: PushEnvironmentType;
}

export default OCApp;
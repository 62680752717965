import styles from "./StateContainer.module.css";

interface ContainerProps {
  title: string;
  subtitle: string;
}

const StateContainer: React.FC<ContainerProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.container}>
      <strong>{title}</strong>
      <p>{subtitle}</p>
    </div>
  );
};

export default StateContainer;

import { IonContent, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonProgressBar } from '@ionic/react';
import { useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import DeviceEventItem from '../components/DeviceEventItem';
import { useEventsQuery } from '../query/eventsQuery';
import StateContainer from '../components/StateContainer';

const Activity: React.FC = () => {
    const eventsQuery = useEventsQuery();

    const Footer = useCallback(() => {
        return (
            <IonInfiniteScroll onIonInfinite={async (event) => {
                await eventsQuery.fetchNextPage();
                await event.target.complete();
                }} disabled={!eventsQuery.hasNextPage} threshold="100px">
                <IonInfiniteScrollContent />
            </IonInfiniteScroll>
        )
    }, [eventsQuery]);

    const events = useMemo(() => {
        return eventsQuery.data?.pages.reduce((acc, page) => acc.concat(page), []) ?? [];
    }, [eventsQuery.data]);

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Activity Feed</IonTitle>
                </IonToolbar>
                {eventsQuery.isLoading && !eventsQuery.isFetchingNextPage && <IonProgressBar type="indeterminate" />}
            </IonHeader>
            <IonContent fullscreen scrollY={false}>
                {eventsQuery.isError && ({
                    401: <StateContainer title="Not logged in" subtitle="Please log in to see your activity feed." />
                }[(eventsQuery.error as any).code as number] ?? <StateContainer title="Couldn't load devices" subtitle={eventsQuery.error.message} />)}
                {eventsQuery.isSuccess && <Virtuoso
                    className="ion-content-scroll-host"
                    data={events}
                    itemContent={(index, event) => <DeviceEventItem deviceId={event.deviceId} eventId={event.eventId} />}
                    components={{ Footer }}
                    increaseViewportBy={{ top: 5, bottom: 5 }}
                />}
            </IonContent>
        </IonPage >
    );
};

export default Activity;

import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { JsonEditor } from 'json-edit-react';

const JsonViewerModal = ({ dismiss, data }: { dismiss: (data?: null, role?: string) => void, data: Object }) => {
    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>JSON Viewer</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="medium" onClick={() => dismiss(null, 'close')}>
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <JsonEditor minWidth="100%" maxWidth="100%" data={data} restrictEdit={true} restrictDelete={true} restrictAdd={true} />
            </IonContent>
        </IonPage>
    );
};

export default JsonViewerModal;
import OCGatewayClient from "../client/OCGatewayClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Device } from "../models/Device";

export function useDeviceTimeZoneMutation(deviceId: string) {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (timeZone: string) => {
            await OCGatewayClient.request('updateDeviceTimeZone', {
                deviceId,
                timeZone
            });
            return timeZone;
        },
        onMutate: async (timeZone: string) => {
            await queryClient.cancelQueries({ queryKey: ['getDevice', deviceId] });

            const previousDevice = queryClient.getQueryData(['getDevice', deviceId]) as Device;

            queryClient.setQueryData(['getDevice', deviceId], (queryData: any) => {
                return Object.assign({}, queryData, { timeZone });
            });

            return { previousDevice };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['getDevice', deviceId], (queryData: any) => {
                return Object.assign({}, queryData, { timeZone: context?.previousDevice?.timeZone });
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['getDevice', deviceId] });
        }
    });

    return mutation;
};
import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import OCGatewayClient from '../client/OCGatewayClient';

export function useCurrentUserQuery() {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      return OCGatewayClient.user$.getValue();
    },
    staleTime: Infinity,
    gcTime: 1000 * 60 * 60 * 24,
    networkMode: 'always',
    retry: false,
  });

  useEffect(() => {
    const subscription = OCGatewayClient.user$.subscribe((user) => {
      console.log('(useCurrentUserQuery) User changed, updating query data...');
      queryClient.setQueryData(['currentUser'], user);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient]);

  return query;
}
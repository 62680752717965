import { SplashScreen } from '@capacitor/splash-screen';
import { Redirect, Route } from 'react-router-dom';
import { useEffect } from 'react';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { history } from './utils/history';

import Devices from './pages/Devices';
import Device from './pages/Device';
import Account from './pages/Account';
import Event from './pages/Event';
import Activity from './pages/Activity';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';
import AccountDelete from './pages/AccountDelete';

setupIonicReact();

const App: React.FC = () => {
  useEffect(() => {
    console.log('App mounted');
    SplashScreen.hide();
  }, []);

  return (<IonApp>
    <IonReactRouter history={history}>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/activity">
            <Activity />
          </Route>
          <Route exact path="/devices">
            <Devices />
          </Route>
          <Route path="/devices/:deviceId" component={Device}>
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>
          <Route path="/account/delete">
            <AccountDelete />
          </Route>
          <Route exact path="/">
            <Redirect to="/activity"/>
          </Route>
          <Route path="/events/:deviceId/:eventId" component={Event}>
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" translucent>
          <IonTabButton tab="activity" href="/activity">
            <IonIcon aria-hidden="true" src="/assets/activity/activity-icon.svg" />
            <IonLabel>Activity</IonLabel>
          </IonTabButton>
          <IonTabButton tab="devices" href="/devices">
            <IonIcon aria-hidden="true" src="/assets/device/onlycat-device-icon.svg" />
            <IonLabel>Devices</IonLabel>
          </IonTabButton>
          <IonTabButton tab="account" href="/account">
            <IonIcon aria-hidden="true" src="/assets/settings/account-settings-icon.svg" />
            <IonLabel>Account</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
  )
};

export default App;

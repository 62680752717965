import { IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar, IonProgressBar, IonIcon, IonButton, IonButtons, IonInfiniteScroll, IonInfiniteScrollContent, useIonModal } from '@ionic/react';
import { add } from 'ionicons/icons';
import AddDeviceModal from '../modals/AddDeviceModal';
import { useDevicesQuery } from '../query/devicesQuery';
import StateContainer from '../components/StateContainer';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import DeviceListItem from '../components/DeviceListItem';
import JiraTicketSearchModal from '../modals/JiraTicketSearchModal';
import { useCurrentUserQuery } from '../query/currentUserQuery';
import { useHistory } from 'react-router-dom';

const Devices: React.FC = () => {
  const devicesQuery = useDevicesQuery();
  const history = useHistory();

  const [presentAddModal, dismissAddModal] = useIonModal(AddDeviceModal, {
    dismiss: (data: string, role: string) => dismissAddModal(data, role)
  });

  const [presentJiraTicketSearchModal, dismissJiraTicketSearchModal] = useIonModal(JiraTicketSearchModal, {
    dismiss: (data: string, role: string) => {
      dismissJiraTicketSearchModal(data, role);

      if(role === 'navigate') {
        history.push(data);
      }
    }
  });

  const List = forwardRef((props, ref: ForwardedRef<any> | null) => {
    return <IonList ref={ref} {...props} inset />
  })

  const Footer = useCallback(() => {
    return (
      <IonInfiniteScroll onIonInfinite={async (event) => {
        await devicesQuery.fetchNextPage();
        await event.target.complete();
      }} disabled={!devicesQuery.hasNextPage} threshold="100px">
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    )
  }, [devicesQuery]);

  const devices = useMemo(() => {
    return devicesQuery.data?.pages.reduce((acc, page) => acc.concat(page), []) ?? [];
  }, [devicesQuery.data]);

  const currentUserQuery = useCurrentUserQuery();
  const isAdmin = currentUserQuery.data?.userLevel === 'ADMIN';

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Devices</IonTitle>
          <IonButtons slot="primary">
            <IonButton disabled={!devicesQuery.isSuccess} onClick={() => presentAddModal()}>
              <IonIcon slot="icon-only" icon={add} aria-label="Add Device" />
            </IonButton>
            {isAdmin && <IonButton onClick={() => presentJiraTicketSearchModal({
              presentingElement: document.getElementsByTagName('ion-router-outlet')[0]
            })}>
              <IonIcon slot="icon-only" src="/assets/admin/find-ticket.svg" aria-label="Find Ticket" />
            </IonButton>}
          </IonButtons>
        </IonToolbar>
        {devicesQuery.isLoading && !devicesQuery.isFetchingNextPage && <IonProgressBar type="indeterminate" />}
      </IonHeader>
      <IonContent fullscreen scrollY={false}>
        {devicesQuery.isError && ({
          401: <StateContainer title="Not logged in" subtitle="Please log in to see your devices." />
        }[(devicesQuery.error as any).code as number] ?? <StateContainer title="Couldn't load devices" subtitle={devicesQuery.error.message} />)}
        {devicesQuery.isSuccess &&
          <Virtuoso
            className="ion-content-scroll-host"
            data={devices}
            itemContent={(index, device) => <DeviceListItem deviceId={device.deviceId} />}
            components={{ Footer, List }}
            increaseViewportBy={{ top: 5, bottom: 5 }}
          />
        }
      </IonContent>
    </IonPage >
  );
};

export default Devices;

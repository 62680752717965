import { IonContent, IonHeader, IonChip, IonIcon, IonList, IonPage, IonTitle, IonToolbar, IonPopover, IonButton, IonText, useIonModal, IonSpinner, IonThumbnail, useIonAlert } from '@ionic/react';
import { IonButtons, IonBackButton, IonItem, IonLabel, IonItemDivider, IonProgressBar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import DeviceEventItem from '../components/DeviceEventItem';
import { useDeviceEventsQuery } from '../query/deviceEventsQuery';
import { useMemo } from 'react';
import { useDeviceQuery, useUpdateDeviceMutation } from '../query/deviceQuery';
import { chevronExpandOutline, copyOutline, createOutline, ellipsisVertical, shieldHalf, unlinkOutline, wifi } from "ionicons/icons";
import DeviceTransitPolicyTile from '../components/DeviceTransitPolicyTile';
import DeviceTransitPolicyAddButton from '../components/DeviceTransitPolicyAddButton';
import { useDeviceTransitPoliciesQuery } from '../query/deviceTransitPoliciesQuery';
import styles from './Device.module.css';
import OCGatewayClient from '../client/OCGatewayClient';
import useUX from '../utils/ux';
import DeviceTimeZoneModal from '../modals/DeviceTimeZoneModal';
import { useJiraDeviceTicketsQuery } from '../query/jiraDeviceTicketsQuery';
import { useCurrentUserQuery } from '../query/currentUserQuery';

interface DevicePageProps
    extends RouteComponentProps<{
        deviceId: string;
    }> { }

const Device: React.FC<DevicePageProps> = ({ match }) => {
    const { deviceId } = match.params;
    const deviceQuery = useDeviceQuery(deviceId);
    const deviceMutation = useUpdateDeviceMutation(deviceId);
    const deviceEventsQuery = useDeviceEventsQuery(deviceId);
    const deviceTransitPoliciesQuery = useDeviceTransitPoliciesQuery(deviceId);
    const { presentResult } = useUX();

    // Info for Admins
    const currentUserQuery = useCurrentUserQuery();
    const isAdmin = currentUserQuery.data?.userLevel === 'ADMIN';
    const deviceJiraTicketsQuery = useJiraDeviceTicketsQuery(isAdmin ? deviceId : undefined);

    const events = useMemo(() => {
        return deviceEventsQuery.data?.pages.reduce((acc, page) => acc.concat(page), []) ?? [];
    }, [deviceEventsQuery.data]);

    const unlock = async () => {
        presentResult(() => OCGatewayClient.request('runDeviceCommand', { deviceId, command: 'unlock' }), "Remote Unlock Requested");
    };

    const reboot = async () => {
        presentResult(() => OCGatewayClient.request('runDeviceCommand', { deviceId, command: 'reboot' }), "Reboot Requested");
    };

    const copyDeviceId = () => {
        presentResult(async () => {
            await navigator.clipboard.writeText(deviceId);
        }, "Device ID Copied");
    };

    const [presentTimeZoneModal, dismissTimeZoneModal] = useIonModal(DeviceTimeZoneModal, {
        dismiss: (data: string, role: string) => dismissTimeZoneModal(data, role),
        deviceId
    });

    const [presentAlert] = useIonAlert();
    const renameDevice = async () => {
        presentAlert({
            header: 'Rename Device',
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    value: deviceQuery.data?.description,
                    placeholder: 'Device Name',
                    attributes: {
                        maxlength: 64,
                    },
                }
            ],
            buttons: [
                'Cancel',
                {
                    text: 'Save',
                    handler: (data) => {
                        if (data.name === deviceQuery.data?.description) return;
                        presentResult(() => deviceMutation.mutateAsync({ description: data.name || null }), "Device Renamed");
                    }
                }
            ]
        });
    }

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/devices" />
                    </IonButtons>
                    <IonTitle>Device</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton id="device-more" fill="clear">
                            <IonIcon slot="icon-only" icon={ellipsisVertical} aria-label="Device Control Menu" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {(deviceQuery.isLoading || (deviceEventsQuery.isLoading && !deviceEventsQuery.isFetchingNextPage)) && <IonProgressBar type="indeterminate" />}
            </IonHeader>
            <IonContent fullscreen>
                <IonList inset={true}>
                    <IonItemDivider>Device Information</IonItemDivider>
                    <IonItem button detail={true} detailIcon={copyOutline} onClick={copyDeviceId}>
                        <IonLabel>Device ID</IonLabel>
                        <IonText slot="end" color="dark">{deviceId}</IonText>
                    </IonItem>
                    <IonItem button detail={true} detailIcon={createOutline} onClick={() => renameDevice()}>
                        <IonLabel className="ion-text-nowrap">Device Name</IonLabel>
                        <IonText slot="end" color={deviceQuery.data?.description ? 'dark' : 'medium'}>{deviceQuery.data?.description ?? 'Not Set'}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Device Status</IonLabel>
                        {deviceQuery.data?.connectivity
                            ? <IonChip color={deviceQuery.data.connectivity.connected ? 'success' : 'danger'}>
                                <IonIcon icon={deviceQuery.data.connectivity.connected ? wifi : unlinkOutline} />
                                <IonLabel>{deviceQuery.data.connectivity.connected ? 'Online' : 'Offline'}</IonLabel>
                            </IonChip>
                            : '-'}
                    </IonItem>
                    <IonItem onClick={() => presentTimeZoneModal()} button detail detailIcon={chevronExpandOutline}>
                        <IonLabel>Time Zone</IonLabel>
                        <IonText slot="end" color="medium">{deviceQuery.data?.timeZone || 'Not Set'}</IonText>
                    </IonItem>
                </IonList>
                {isAdmin && <IonList inset={true} className={styles.jiraTickets}>
                    <IonItemDivider>
                        Relevant Tickets
                        {deviceJiraTicketsQuery.isLoading ? <IonSpinner name="dots" slot="end" /> : <IonIcon icon={shieldHalf} size="small" slot="end" />}
                    </IonItemDivider>
                    {deviceJiraTicketsQuery.isError && <IonItem>
                        <IonLabel>Error Loading Tickets</IonLabel>
                    </IonItem>}
                    {deviceJiraTicketsQuery.isSuccess &&
                        deviceJiraTicketsQuery.data?.map((ticket: any) =>
                            <IonItem key={ticket.key} onClick={e => window.open(ticket.url, '_blank')} button>
                                <IonThumbnail aria-hidden slot="start">
                                    <img alt="" src={ticket.iconUrl} />
                                </IonThumbnail>
                                <IonLabel>
                                    <IonText>{ticket.summary}</IonText>
                                    <p>{ticket.key}</p>
                                </IonLabel>
                                <IonText slot="end" color="medium">{ticket.status}</IonText>
                            </IonItem>)
                    }
                </IonList>}
                <IonList className={styles.transitPolicies}>
                    <IonItemDivider>Door Policy</IonItemDivider>
                    {deviceTransitPoliciesQuery.data && <IonItem lines="none">
                        <div>
                            {deviceTransitPoliciesQuery.data.map((policy) => <DeviceTransitPolicyTile
                                key={policy.deviceTransitPolicyId}
                                deviceId={deviceId}
                                deviceTransitPolicyId={policy.deviceTransitPolicyId!}
                                active={policy.deviceTransitPolicyId === deviceQuery.data?.deviceTransitPolicyId}
                            />)}
                            <DeviceTransitPolicyAddButton deviceId={deviceId} />
                        </div>
                    </IonItem>}
                </IonList>
                <IonList inset={true}>
                    <IonItemDivider>Recent Events</IonItemDivider>
                    {events?.map((event: any) => <DeviceEventItem key={event.eventId} deviceId={event.deviceId} eventId={event.eventId} />)}
                </IonList>
            </IonContent>
            <IonPopover trigger="device-more" dismissOnSelect>
                <IonContent>
                    <IonList>
                        <IonItem button={true} detail={false} onClick={unlock}>Remote Unlock</IonItem>
                        <IonItem button={true} detail={false} onClick={reboot}>Reboot</IonItem>
                    </IonList>
                </IonContent>
            </IonPopover>
        </IonPage >
    );
};

export default Device;

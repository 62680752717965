import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonProgressBar, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import React, { memo, useContext, useEffect, useState } from "react";
import OCGatewayGlient from '../client/OCGatewayClient';
import OCAuth from '../client/OCAuth';
import useUX from '../utils/ux';
import { NavContext } from '@ionic/react';
import { logOutOutline, trashOutline } from 'ionicons/icons';
import styles from './Account.module.css';

const Account: React.FC = () => {
    const { goBack } = useContext(NavContext);
    const [user, setUser] = useState<any>();
    const [presentAlert] = useIonAlert();
    const { isConnecting, presentResult } = useUX();

    const login = async () => {
        await OCAuth.login();
    };

    const deleteAccount = async () => {
        await presentAlert({
            header: 'Delete Account',
            message: 'Are you sure you want to delete your account? This action cannot be undone.',
            buttons: [
                'Cancel',
                {
                    text: 'Delete',
                    role: 'destructive',
                    handler: async () => {
                        presentResult(async () => {
                            await OCAuth.deleteAccount();
                            goBack('/account');
                        }, "Your account has been deleted");
                    }
                }
            ]
        });
    }

    useEffect(() => {
        const subscriptions = [
            OCGatewayGlient.user$.subscribe(value => setUser(value))
        ];

        return () => {
            subscriptions.forEach(s => s.unsubscribe());
        };
    }, []);

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/account" />
                    </IonButtons>
                    <IonTitle>Delete Account</IonTitle>
                    {!!(isConnecting || typeof user === 'undefined') && <IonProgressBar type="indeterminate" />}
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.accountContent} fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Delete Account</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {<div className={styles.avatarContainer}>
                    <IonAvatar>
                        <img src={user?.avatarUrl ?? '/assets/account/default-avatar.svg'} alt={user?.name ?? 'Guest'} referrerPolicy="no-referrer" />
                    </IonAvatar>
                    <h1>{user?.name ?? "Not Signed In"}</h1>
                    <h2>{user?.email}</h2>
                </div>}

                <IonCard color="danger">
                    <IonCardHeader>
                        <IonCardTitle>OnlyCat Account Deletion</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {!user && <p>Sign in with the account you'd like to delete.</p>}
                        {user && <p>Deleting your account will remove all your data from our servers. This action cannot be undone.</p>}
                    </IonCardContent>
                </IonCard>

                {!user && <IonButton onClick={login} fill="outline" shape="round" color="primary" expand="full">
                    <IonIcon slot="start" icon={logOutOutline}></IonIcon>
                    Sign In
                </IonButton>}

                {user && <IonButton onClick={deleteAccount} fill="outline" shape="round" color="danger" expand="full">
                    <IonIcon slot="start" icon={trashOutline}></IonIcon>
                    Delete Account
                </IonButton>}
            </IonContent>
        </IonPage >
    );
};

export default memo(Account);

import { IonChip, IonIcon, IonLabel, IonSkeletonText, useIonModal } from "@ionic/react";
import { useRfidProfileQuery } from "../query/rfidProfileQuery";
import RfidProfileModal from "../modals/RfidProfileModal";
import { modalController } from "@ionic/core";

interface RfidChipProps {
    rfidCode: string,
    deviceId?: string
}

const RfidChip: React.FC<RfidChipProps> = ({ rfidCode, deviceId }) => {
    const query = useRfidProfileQuery(rfidCode, deviceId);
    const [presentProfile, dismissProfile] = useIonModal(RfidProfileModal, { 
        dismiss: (data: string, role: string) => dismissProfile(data, role),
        rfidCode
    });

    const openProfile = (e: any) => {
        console.log('Open profile for RFID:', rfidCode);

        (async () => {
            presentProfile({
                presentingElement: await modalController.getTop() || document.getElementsByTagName('ion-router-outlet')[0]
            });
        })();

        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    return (
        <IonChip key={rfidCode} color="dark" onClick={openProfile}>
            <IonIcon src="/assets/rfid/tag-simple.svg" aria-hidden />
            {query.isLoading
                ? <IonLabel><IonSkeletonText style={{ width: '42px' }} animated /></IonLabel>
                : <IonLabel>{query.data?.label ?? rfidCode}</IonLabel>}
        </IonChip>
    );
};

export default RfidChip;
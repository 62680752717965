import OCGatewayClient from "../client/OCGatewayClient";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeviceTransitPolicy } from "../models/DeviceTransitPolicy";

export function useDeviceTransitPoliciesQuery(deviceId: string) {
    const query = useQuery({
        queryKey: ['getDeviceTransitPolicies', deviceId],
        queryFn: async () => {
            return OCGatewayClient.request('getDeviceTransitPolicies', {
                deviceId
            }) as Promise<DeviceTransitPolicy[]>;
        },
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    return query;
}

export function useDeviceTransitPolicyCreateMutation(deviceId: string) {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (deviceTransitPolicy: Partial<DeviceTransitPolicy>) => {
            await OCGatewayClient.request('createDeviceTransitPolicy', {
                ...deviceTransitPolicy,
                deviceId
            });
            return deviceTransitPolicy;
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['getDeviceTransitPolicies', deviceId] });
        }
    });

    return mutation;
}

export function useDeviceTransitPolicyDeleteMutation(deviceId: string) {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (deviceTransitPolicyId: number) => {
            await OCGatewayClient.request('deleteDeviceTransitPolicy', {
                deviceTransitPolicyId
            });
            return deviceTransitPolicyId;
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['getDeviceTransitPolicies', deviceId] });
        }
    });

    return mutation;
}
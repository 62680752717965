import { useDeviceQuery } from "../query/deviceQuery";
import { useTimeZonesQuery } from "../query/timeZonesQuery";
import { useState } from "react";
import { checkmark, close } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonSearchbar,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonIcon,
} from "@ionic/react";
import { useDeviceTimeZoneMutation } from "../query/deviceTimeZoneMutation";

const DeviceTimeZoneModal = ({ dismiss, deviceId }: { dismiss: (data?: string | null | undefined | number, role?: string) => void, deviceId: string }) => {
  const timeZonesQuery = useTimeZonesQuery();
  const [searchQuery, setSearchQuery] = useState('');
  const deviceQuery = useDeviceQuery(deviceId);
  const deviceTimeZone = deviceQuery.data?.timeZone;
  const mutation = useDeviceTimeZoneMutation(deviceId);

  const saveTimeZone = async (timeZone: string) => {
    await mutation.mutateAsync(timeZone);

    dismiss(timeZone, 'select');
  };

  const filteredTimeZones = timeZonesQuery.data?.filter((tz) => tz.timeZone.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Device Time Zone</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => dismiss(null, 'cancel')}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchQuery}
            onIonInput={(e: any) => setSearchQuery(e.target.value)} />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {filteredTimeZones?.map((tz, index) => (
            <IonItem disabled={mutation.isPending} detail={false}
              key={tz.timeZone}
              button
              onClick={() => saveTimeZone(tz.timeZone)}>
              <IonLabel>{tz.timeZone}</IonLabel>
              {tz.timeZone === deviceTimeZone && <IonIcon slot="end" icon={checkmark} />}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default DeviceTimeZoneModal;
import { DeviceEvent } from "../models/DeviceEvent";
import OCGatewayClient from "../client/OCGatewayClient";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

export interface IDevice {
    deviceId: string;
    description?: string;
}

let subscribed = false;

export function useDeviceEventsQuery(deviceId: string) {
    const queryClient = useQueryClient();

    const query = useInfiniteQuery({
        queryKey: ['getDeviceEvents', deviceId],
        queryFn: async ({pageParam : beforeGlobalId}): Promise<any> => {
            if (!beforeGlobalId) {
                return OCGatewayClient.request('getDeviceEvents', {
                    deviceId,
                    subscribe: true
                }) as Promise<DeviceEvent[]>;
            }
            else {
                return OCGatewayClient.request('getDeviceEvents', {
                    deviceId,
                    subscribe: !beforeGlobalId,
                    beforeGlobalId
                }) as Promise<DeviceEvent[]>;
            }
        },
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false,
        getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
            return lastPage.length ? lastPage[lastPage.length - 1].globalId : undefined;
        },
        initialPageParam: undefined
    });

    if(!subscribed) {
        OCGatewayClient.user$.subscribe(user => {
            console.log(`(deviceEventsQuery) User changed, invalidating user events...`);
            queryClient.invalidateQueries({
                queryKey: ['getDeviceEvents']
            });
        });

        OCGatewayClient.socket.on('deviceEventUpdate', (data: any) => {
            queryClient.setQueryData(['getDeviceEvents', data.deviceId], (queryData: any) => {
                const pages: Array<DeviceEvent[]> = queryData?.pages;

                if(!pages?.length) {
                    console.log('deviceEventUpdate (ignored)', data);
                    return
                }

                if(data.type === 'create')
                {
                    console.log('deviceEventUpdate (relevant create)', data);

                    return {
                        pages: [[data.body, ...pages[0]], ...pages.slice(1)],
                        pageParams: queryData.pageParams
                    };
                }

                console.log('deviceEventUpdate (no match)', data);
            });
        });

        subscribed = true;
    }

    return query;
}
import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery } from "@tanstack/react-query";

export function useJiraDeviceTicketsQuery(deviceId?: string) {
    const query = useQuery({
        queryKey: ['getJiraTicketsForDevice', deviceId],
        enabled: !!deviceId,
        queryFn: async (): Promise<any> => {
            return OCGatewayClient.request('getJiraTicketsForDevice', {
                deviceId
            });
        },
        staleTime: Infinity,
        gcTime: 1000 * 60 * 60 * 1,
        networkMode: 'always',
        retry: false
    });

    return query;
}

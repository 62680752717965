import { Auth0Client } from '@auth0/auth0-spa-js';
import { Browser } from '@capacitor/browser';
import { App as CapApp } from '@capacitor/app';
import { isPlatform } from '@ionic/react';
import { User } from '../models/User';
import OCGatewayClient from './OCGatewayClient';

export class OCAuth {
    private auth0Client: Auth0Client;

    constructor() {
        this.auth0Client = new Auth0Client({
            domain: 'auth.onlycat.com',
            clientId: 'rqwpeo4jPr7QmqR2M4hfvOsL7fRr8FTt',
            cacheLocation: 'localstorage'
        });
    }

    handleRedirects() {
        CapApp.addListener('appUrlOpen', async ({ url }) => {
            if (this.isRedirectCallback(url)) {
                this.handleRedirectCallback(url);
            }

            // No-op on Android
            await Browser.close();
        });

        if (this.isRedirectCallback(window.location.href)) {
            this.handleRedirectCallback();
        }
    }

    public isRedirectCallback(url: string): boolean {
        return url.includes('state') && (url.includes('code') || url.includes('error'));
    }

    public async login(): Promise<void> {
        const isNative = isPlatform('hybrid');

        await this.auth0Client.loginWithRedirect({
            authorizationParams: {
                redirect_uri: isNative ? 'app.onlycat://onlycat.uk.auth0.com/capacitor/app.onlycat/callback'
                    : window.location.origin + '/account',
            },
            async openUrl(url: string) {
                console.log('openUrl', url);

                await Browser.open({
                    url,
                    windowName: "_self"
                });
            }
        });
    }

    public async logout(): Promise<void> {
        await OCGatewayClient.request('logout', {});

        OCGatewayClient.user$.next(null);
    }

    public async deleteAccount(): Promise<void> {
        await OCGatewayClient.request('deleteAccount', {});

        OCGatewayClient.user$.next(null);
    }

    public async isAuthenticated(): Promise<boolean> {
        return await this.auth0Client.isAuthenticated();
    }

    public async getUser(): Promise<any> {
        return await this.auth0Client.getUser();
    }

    public async handleRedirectCallback(url?: string): Promise<void> {
        try {
            console.log('handleRedirectCallback', url);

            await this.auth0Client.handleRedirectCallback(url);

            const token = await this.auth0Client.getTokenSilently();
            const user = await OCGatewayClient.request('loginWithAuth0', { token });

            OCGatewayClient.user$.next(user as User);
        }
        catch (err: any) {
            if (err.error !== 'missing_transaction') {
                document.dispatchEvent(new CustomEvent('oc:auth:error', {
                    detail: err
                }));
            }

            console.error(err);
        }
    }
}

export default new OCAuth();
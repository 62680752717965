import { IonChip, useIonAlert } from "@ionic/react";

const BetaChip: React.FC = () => {
    const [presentAlert] = useIonAlert();

    const presentInfo = () => {
        presentAlert({
            header: 'Beta Feature',
            message: 'This feature is currently in development. If you encounter any issues, please let us know.',
            buttons: ['OK']
        });
    }

    return (
        <IonChip color="warning" onClick={presentInfo}>
            Beta
        </IonChip>
    );
};

export default BetaChip;
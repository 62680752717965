import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonSkeletonText, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { useDeviceLastSeenRfidCodesQuery } from '../query/deviceLastSeenRfidCodesQuery';
import { useRfidProfileQuery } from '../query/rfidProfileQuery';
import { MouseEventHandler } from 'react';
import { eyeOutline, pricetagOutline, refreshOutline } from 'ionicons/icons';
import Timestamp from '../components/Timestamp';

interface SeenRfidListItemProps {
    rfidCode: string;
    deviceId: string;
    timestamp?: Date;
    lastSeen?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<Element>
}

const SeenRfidListItem: React.FC<SeenRfidListItemProps> = ({ rfidCode, deviceId, timestamp, disabled, onClick }) => {
    const query = useRfidProfileQuery(rfidCode, deviceId);

    return (
        <IonItem button disabled={disabled} onClick={onClick}>
            <IonLabel>
                {query.isLoading && <IonSkeletonText style={{ width: '100px' }} animated />}
                {query.data?.label && <h2>{query.data?.label}</h2>}
                <p>
                    <IonIcon icon={pricetagOutline} aria-hidden />
                    {rfidCode}
                </p>
                <IonNote>
                    <IonIcon icon={eyeOutline} aria-hidden />
                    <Timestamp timestamp={timestamp} />
                </IonNote>
            </IonLabel>
        </IonItem>
    );
};

const RfidProfileModal = ({ dismiss, deviceId, disabledRfidCodes }: {
    dismiss: (rfidCode?: string, role?: string) => void,
    deviceId: string,
    disabledRfidCodes: string[]
}) => {
    const query = useDeviceLastSeenRfidCodesQuery(deviceId);

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => dismiss(undefined, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Select Microchip</IonTitle>
                    <IonButtons slot="end">
                        <IonButton title="Refresh" onClick={() => query.refetch()} disabled={query.isFetching}>
                            <IonIcon icon={refreshOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonList inset={true}>
                    {query.data?.map(({ rfidCode, timestamp }) => (
                        <SeenRfidListItem key={rfidCode} rfidCode={rfidCode} deviceId={deviceId} timestamp={timestamp} disabled={disabledRfidCodes?.includes(rfidCode)} onClick={() => dismiss(rfidCode, 'confirm')} />
                    ))}
                </IonList>
                <IonNote color="medium" >
                    <p className="ion-padding-horizontal">
                        Microchips scanned by the device will appear here.
                    </p>
                </IonNote>
            </IonContent>
        </IonPage>
    );
};

export default RfidProfileModal;
import OCGatewayClient from "../client/OCGatewayClient";
import { useQuery } from "@tanstack/react-query";

type DeviceLastSeenRfidCode = {
    rfidCode: string;
    timestamp: Date;
}

export function useDeviceLastSeenRfidCodesQuery(deviceId: string) {
    const query = useQuery({
        queryKey: ['getLastSeenRfidCodesByDevice', deviceId],
        queryFn: async () => {
            return OCGatewayClient.request('getLastSeenRfidCodesByDevice', {
                deviceId
            }) as Promise<DeviceLastSeenRfidCode[]>;
        },
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 60 * 24,
        networkMode: 'always',
        retry: false
    });

    return query;
}